import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Style from "./CardLink.module.scss"

const CardLink = props => {
  return (
    <Link to={props.to} className="flex self-stretch w-full">
      <div
        className={`w-full shadow rounded text-center p-5 transition-colors duration-300 self-stretch ${Style.box}`}
      >
        <div className="mb-3">{props.svg}</div>
        <div className={`transition-colors duration-300 ${Style.title}`}>
          {props.title}
        </div>
      </div>
    </Link>
  )
}

CardLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  svg: PropTypes.node.isRequired,
}

export default CardLink
